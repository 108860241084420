<template>
  <div class="saleSampleSettlementDaily">
    <h1>销售结算按日自由汇总</h1>
    <div style="display: flex;">
      <div style="width: 400px;">
        <el-form>
          <el-form-item label="客户">
            <el-select v-model="searchParam.custom" filterable style="width: 240px;" clearable>
              <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
            </el-select>
            <el-button type="primary" style="margin-left: 10px;" @click="commitFindList()">搜索</el-button>
          </el-form-item>
          <el-form-item label="日期">
            <div>
              <div v-for="(daily, index) in searchParam.dailyList" :key="index">
                <el-date-picker v-model="searchParam.dailyList[index]" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
                <el-button type="primary" style="margin-left: 10px;" circle @click="searchParam.dailyList.splice(index + 1, 0, [])">+</el-button>
                <el-button type="info" style="margin-left: 10px;" v-if="index" circle @click="searchParam.dailyList.splice(index, 1)">-</el-button>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 20px;width: calc(100% - 500px);">
        <el-table :data="data.list" border>
          <el-table-column prop="daily.dateGe" label="起" show-overflow-tooltip />
          <el-table-column prop="daily.dateLe" label="止" show-overflow-tooltip />
          <el-table-column prop="custom" label="客户" show-overflow-tooltip />
          <el-table-column prop="invoiceQuantity" label="发票数量" show-overflow-tooltip />
          <el-table-column prop="invoiceAmount" label="开票金额" show-overflow-tooltip />
          <el-table-column prop="saleAmount" label="结算金额" show-overflow-tooltip />
          <el-table-column prop="differenceAmount" label="差异值" show-overflow-tooltip />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const data = reactive({
  total: 0,
  list: [],
})

const show = reactive({
  selected: [],
  customList: [],
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.dailyList = [[]]
  searchParam.custom = null
}
resetSearchParam()

const commitFindList = () => {
  if (!searchParam.custom) {
    ElMessage.error('请选择客户')
    return
  }
  if (!searchParam.dailyList || searchParam.dailyList.length == 0) {
    ElMessage.error('请选择日期')
    return
  }
  const params = {}
  const dailyList = []
  for (const i in searchParam.dailyList) {
    const daily = searchParam.dailyList[i]
    if (daily && daily.length == 2) {
      const paramDaily = {}
      paramDaily.dateGe = util.parseTime(daily[0], '{y}-{m}-{d}')
      paramDaily.dateLe = util.parseTime(daily[1], '{y}-{m}-{d}')
      dailyList.push(paramDaily)
    } else {
      ElMessage.error('日期不可空白不填')
      return
    }
  }
  params.dailyList = dailyList
  params.custom = searchParam.custom
  api.post('/backend/saleSample/getSettlementDaily', params).then(res => {
    data.list = res.list
    data.total = res.total
  })
}
</script>

<style lang="less"></style>